<template>
  <div
    class="d-flex flex-column"
    style="row-gap: 1.34rem"
  >
    <b-alert
      show
      variant="primary"
      class="px-75 py-1 mb-0 d-flex align-items-center justify-content-between"
    >
      <div class="d-flex align-items-center">
        <b-avatar variant="primary">
          <feather-icon icon="UserIcon" />
        </b-avatar>

        <span class="d-flex flex-column ml-75">
          <strong>
            {{ $t('Sender') }}
          </strong>

          <span>
            {{ value.userMailbox.name }}
            ({{ value.userMailbox.imapHost }})
          </span>
        </span>
      </div>
    </b-alert>

    <div>
      <h5 class="text-primary mb-25">
        {{ $t('Message') }}:
      </h5>

      <p class="d-flex flex-column">
        <strong>{{ value.subject }}</strong>

        <span v-html="value.content" />
      </p>

      <div class="d-flex flex-column w-100">
        <strong>{{ $t('Footer') }}</strong>

        <div class="mail-footer"
             v-html="footer"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axiosIns from '@/libs/axios'

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    footer: '',
  }),
  async mounted() {
    if (this.value.imageFiles.length) {
      let footerItem = this.value.footer
      const responses = await Promise.all(this.value.imageFiles.map(image => axiosIns.get(`storage/1/download/${image.token}`)))
      this.footerImages = responses.map((e, i) => {
        const filename = `${e.data.data.name}`
        const base64 = `data:image/png;base64,${e.data.data.file}`
        return {
          token: this.value.imageFiles[i]?.token,
          base64,
          cid: filename,
          filename,
        }
      })

      this.footerImages.forEach(image => {
        // cid, filename, token, base64
        if (footerItem.includes(image.cid)) {
          footerItem = footerItem.replaceAll(`cid:${image.filename}`, image.base64)
        }
      })

      this.footer = footerItem
    } else {
      this.footer = this.value.footer
    }
  },
}
</script>

<style lang="scss">
.mail-footer {
  img {
    max-width: 100%;
  }
}
</style>
